import React, { Component } from "react"
import { navigate } from "gatsby"
import { connect } from "react-redux"
import Formsy from 'formsy-react';

import Layout from "../components/layout"
import MyInput from "../components/MyInput"
import ErrrorHandel from "../components/ErrrorHandel";
import MyInputMask from "../components/MyInputMask";
import MyCheckbox from "../components/MyCheckbox";

import SEO from "../components/seo"
import * as actionCreators from '../store/actions';
import { Link } from "react-router-dom";

class CancelPage extends Component {

  state = {
    info:null,
    expErr:null,
    mileage:null,
    errMsg:null,
    quote:null,
    done:null,
    canSubmit:false
  }

  componentDidMount = async() => {
    const { showLoader} = this.props;
    showLoader();

    let param = this.getQueryString(this.props.location.search);
    if(param && param.contractNo){
      this.setState({ contractNo:param.contractNo});
      await this.getFromDirect(param.contractNo);
    }

  }
  getQueryString = (data) => {
    if (data !== '') {
        const search = data.substring(1);
        return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
    }
  }
  

  getFromDirect = async(contractNo) => {
    
    const { hideLoader} = this.props;
        let uri = process.env.REST_API_URL+'contractInfo';
        await fetch(uri,{
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                contractNo: contractNo,
            })
        }).then(res => res.json()).then(async(res) => {
            if(res.status){
                this.setState({ info:res.data});
            } else {
                this.setState({expErr:true});
            }
            hideLoader();
        });
  };

  valChange = (val) => {
    this.setState({mileage:val})
  }

  getQuote = async() =>{
    const { showLoader, hideLoader } = this.props;
    var { mileage, contractNo} = this.state;
    showLoader();
    if(mileage){
        let uri = process.env.REST_API_URL+'cancelQuote';
        await fetch(uri,{
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                contractNo: contractNo,
                mileage: mileage,
            })
        }).then(res => res.json()).then(async(res) => {
            if(res.status){
                //console.log(res.data)
                this.setState({ quote:res.data,errMsg:null});
            } else {
                this.setState({errMsg:res.msg});
            }
            hideLoader();
        });
    } else {
      hideLoader();
      this.setState({errMsg:"Invalid Mileage"})
    }
  }

  createCase = async() =>{
    const { showLoader, hideLoader } = this.props;
    var { quote, contractNo,mileage} = this.state;
    showLoader();
        let uri = process.env.REST_API_URL+'createCase';
        await fetch(uri,{
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                contractNo: contractNo,
                quote: quote,
                mileage:mileage
            })
        }).then(res => res.json()).then(async(res) => {
            if(res.status){
                this.setState({ done:res.caseid,errMsg:null});
            } else {
                this.setState({errMsg:res.msg});
            }
            hideLoader();
        });
  }

  submit = async(model) => {
    const { showLoader, hideLoader } = this.props
    const {contractNo} = this.state;
    showLoader();
    let uri = process.env.REST_API_URL+'crmVoid';
    await fetch(uri,{
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            contractNo: contractNo,
            reason:model.reason
        })
    }).then(res => res.json()).then(async(res) => {
        if(res.status){
            this.setState({ done:1,errMsg:null});
        } else {
            this.setState({errMsg:res.msg});
        }
        hideLoader();
    });
  }

  
  disableButton = () => {
    this.setState({ canSubmit: false});
  }
  enableButton = () => {
    this.setState({ canSubmit: true});
  }
  render() {
    const { expErr,info,mileage,errMsg,quote,done, canSubmit } = this.state;
    if(info){
    } else {
     
      return (
        <Layout layoutType="payment">
          <SEO title="Payment" keywords={[`Extended warranty`]} />
          <section className="banner covered-banner">
            <div className="banner-inner">
              
              {expErr && 
              <div className="container">
                <h1>Sorry!</h1>
                <h5>Your email link has been expired.</h5>
                </div>
              }
              
            </div>
          </section>
      </Layout>
      )
    }
    
    
    return (
      <Layout layoutType="payment">
        <SEO title="Payment" keywords={[`servicecontract.com`, `service contracts`, `Extended warranty`]} />
        <section>
          <div className="custom-container vehinfo">
            <h1>Cancel Contract</h1>
            <div className="body-content confirm-content canq">
              <div className="cofirm-left-content usrinfo">
                <div className="confirm-box">
                  <div className="confirm-box-inner">
                    <ul>
                      <li><span>Contract No</span><p>{info.contractId}</p></li>
                      <li><span>Vin</span><p>{info.vin}</p></li>
                      <li><span>Email</span><p>{info.email}</p></li>
                      <li><span>Vehicle</span><p>{info.vehicle}</p></li>
                      <li><span>Plan</span><p>{info.plan}</p></li>
                      <li><span>Duration</span><p>{info.keepVehicle}</p></li>
                      <li><span>Price</span><p>{info.price}</p></li>
                    </ul>
                    </div>
                </div>
              </div>
                
              {info.canvoid == "cancel" &&  
                <div className="cofirm-right-content planinfo">
                {!quote &&  
                <div className="ccout">
                    <div className="cctitle">Current Mileage?</div>
                    <Formsy>
                    <MyInputMask
                        name="mileage"
                        id="mileage"
                        mask="999999"
                        placeholder="Mileage"
                        className="ccbox"
                        value={mileage}
                        validations="isNumeric"
                        validationError="Please enter a valid number."
                        valueChanges={(e) => this.valChange(e)}
                      />
                    {errMsg && 
                    <div className="ccerr">{errMsg}</div>
                    }
                    <button onClick={() => {this.getQuote()}} className={mileage>0?"ccbtn":"ccbtn disabled"}>Get   Quote</button>
                    </Formsy>
                  </div>
                  }
                  {quote && 
                  <div className="amount-box">
                    <div className="amount-box-inner">
                      <div className="summ">
                      <div className="row"><div className="title">Quote</div></div>
                      
                      {
                        Object.keys(quote).map((s) => {
                          return(
                              <div className="row">
                                <div className="left">{s}</div>
                                <div className="right">{quote[s]}</div>
                              </div> 
                          )
                        })
                      }
                        {!done &&
                        <div className="row">
                          <div className="left">
                          <button onClick={() => {this.createCase()}} className="ccbtn">Create Case</button>
                          </div>
                        </div>
                        }
                        {errMsg && 
                        <div className="ccerr">{errMsg}</div>
                        }
                        {done && 
                        <div className="ccok2">Success. Case Created</div>
                        }


                      </div>
                    </div>
                  </div>
                  }
                </div>
              }


              {info.canvoid == "void" &&  
                <div className="cofirm-right-content planinfo">
                  <div className="ccout">
                    <div className="cctitle">Void Reason</div>
                    <Formsy ref="quoteFrom" onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>
                    <ErrrorHandel formName={'quoteFrom'} self={this} />
                    <div className="payiv">
                    <MyInput name="reason" className="form-control ccbox reason" required
                    validations={{ minLength: 5,maxLength:50 }} validationError="Please enter a valid text."/>
                    </div>
                    {/* <button onClick={() => {this.getQuote()}} className={reason?"ccbtn":"ccbtn disabled"}>Get   Quote</button> */}
                    {errMsg && 
                    <div className="ccerr">{errMsg}</div>
                    }
                    <div className="payiv">
                    <button type="submit" disabled={!canSubmit} className={!canSubmit ? 'btn disabled' : 'btn'}>
                      Void
                    </button>

                    {done && 
                      <div className="ccok2">Successfully Voided</div>
                    }
                    </div>
                    
                    </Formsy>
                  </div>
                </div>
              }
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    step2response: state.register.step2response,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    showLoader: () => dispatch(actionCreators.showLoader()),
    showButtonLoader: () => dispatch(actionCreators.showButtonLoader()),
    hideLoader: () => dispatch(actionCreators.hideLoader()),
    clearAllSteps: () => dispatch(actionCreators.ClearAllSteps())
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(CancelPage);